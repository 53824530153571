
import React from 'react';
import { Button} from 'antd';


export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div';
  tag = item.href ? 'a' : tag;

  //let children = typeof item.children === 'string' && item.children.match(isImg)
  let children = item.name === "image"
    ? React.createElement('img', { src: item.children, alt: 'img' })
    : item.children;


  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children
    });
  }

  if(item.name === "icon") {
    children = item.icon;
  }

  if (item.name )
  return React.createElement(tag, { key: i.toString(), ...item }, children);
};
