/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav3 from './Nav3';
import Banner5 from './Banner5';
import Content0 from './Content0';
import Content1 from './Content1';
import Content3 from './Content3';
import Pricing0 from './Pricing0';
import Pricing2 from './Pricing2';
import Footer0 from './Footer0';

import {
  Nav30DataSource,
  Banner50DataSource,
  Content00DataSource,
  Content10DataSource,
  Content30DataSource,
  Pricing00DataSource,
  Pricing20DataSource,
  Footer00DataSource,
} from './data.source';
import './less/antMotionStyle.less';
import { BackTop, Carousel, Image, Space } from 'antd';
import CookieConsent from 'react-cookie-consent';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',
  //lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
    };
  }

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    
    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
  }

  render() {
    const children = [
      <Nav3
        id="Nav3_0"
        key="Nav3_0"
        dataSource={Nav30DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner5
        id="Banner5_0"
        key="Banner5_0"
        dataSource={Banner50DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content0
        id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content1
        id="Content1_0"
        key="Content1_0"
        dataSource={Content10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content3
        id="Content3_0"
        key="Content3_0"
        dataSource={Content30DataSource}
        isMobile={this.state.isMobile}
      />,
      <Pricing0
        id="Pricing0_0"
        key="Pricing0_0"
        dataSource={Pricing00DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Pricing2
      //   id="Pricing2_0"
      //   key="Pricing2_0"
      //   dataSource={Pricing20DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {this.state.show && children}
        <BackTop />
        <CookieConsent
          location="top"
          buttonText="Ok"
          cookieName="cookieConsent"
          style={{ background: "#2B373B"}}
          buttonStyle={{ color: "#4e503b", fontSize: "20px" }}
          expires={150}
        >

          This website uses cookies to enhance the user experience. By using this website you agree to our use of cookies - for usage analysis, statistical analysis and for authorizations for secure areas. 
        </CookieConsent>
      </div>
    );
  }
}
